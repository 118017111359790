import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, ReactSelectField, SelectField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useUpdateProduct } from '../api/updateProduct';
import { useProduct } from '../api/getProduct';

export const UpdateProduct = ({ id }) => {
  const productQuery = useProduct({ id });
  const updateProductMutation = useUpdateProduct();

  if (updateProductMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={updateProductMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Update
          </Button>
        }
        title="Update Product"
        submitButton={
          <Button
            form="update-product"
            type="submit"
            size="sm"
            isLoading={updateProductMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-product"
          onSubmit={async (values) => {
            console.log(values);
            await updateProductMutation.mutateAsync({ id, ...values });
          }}
          options={{
            defaultValues: {
              product_title: productQuery.data.product_title,
              acceptable_collaterals: productQuery.data?.acceptable_collaterals?.map((item) => ({
                label: item,
                value: item,
              })),
              service_fee: productQuery.data.service_fee,
              late_fee: productQuery.data.late_fee,
              early_repayment_fee: productQuery.data.early_repayment_fee,
              principal_min: productQuery.data.principal.min,
              principal_max: productQuery.data.principal.max,

              interest_rate_min: productQuery.data.interest_rate.min,
              interest_rate_max: productQuery.data.interest_rate.max,
              period_min: productQuery.data.period.min,
              period_max: productQuery.data.period.max,
              average_lending_rate: productQuery.data.average_lending_rate,
              due_deligence_fee: productQuery.data.applicable_fees.real_estate?.due_diligence_fee,
              stamp_duty_fees:
                productQuery.data?.applicable_fees?.real_estate.mortgage_placement.stamp_duty_fees,
              registration_fees:
                productQuery.data?.applicable_fees?.real_estate.mortgage_placement
                  .registration_fees,
              bank_charges:
                productQuery.data?.applicable_fees?.real_estate.mortgage_placement.bank_charges,
              registrar_fees:
                productQuery.data?.applicable_fees?.real_estate.mortgage_placement.registrar_fees,
              legal_fees:
                productQuery.data?.applicable_fees.real_estate.mortgage_placement.legal_fees,
              caveat_placement_fee:
                productQuery.data?.applicable_fees?.vehicles.caveat_placement_fee,
              insurance_fee: productQuery.data?.applicable_fees?.vehicles.insurance_fee,
              tracker_hire: productQuery.data?.applicable_fees?.vehicles.tracker_fee.hire,
              tracker_buy: productQuery.data?.applicable_fees?.vehicles.tracker_fee.buy,
              valuation_personal_vehicles_or_bikes:
                productQuery.data?.applicable_fees?.vehicles.valuation.personal_vehicles_or_bikes,
              valuation_heavy_vehicles:
                productQuery.data.applicable_fees?.vehicles.valuation.heavy_vehicles,
              valuation_commercial_vehicles:
                productQuery.data.applicable_fees?.vehicles.valuation.commercial_vehicles,
              assessment_fees_computers:
                productQuery.data.applicable_fees?.computers.assessment_fees,
              assessment_fees_television:
                productQuery.data.applicable_fees.television.assessment_fees,
              assessment_fees_smart_phone:
                productQuery.data.applicable_fees.smart_phone.assessment_fees,
              assessment_fees_other_electronics:
                productQuery.data.applicable_fees.other_electronics.assessment_fees,
            },
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <div className="mb-8">
              <InputField
                label="Product Title"
                error={formState.errors['product_title']}
                registration={register('product_title')}
              />
              <ReactSelectField
                label="Select Acceptable Collaterals"
                registration={register('acceptable_collaterals')}
                editorContent={watch('acceptable_collaterals')}
                setValue={setValue}
                isMulti={true}
                name="acceptable_collaterals"
                defaultValue={watch('acceptable_collaterals')}
                options={[
                  {
                    label: 'Real Estate With Land Title',
                    value: 'REAL_ESTATE_WITH_LAND_TITLE',
                  },
                  { label: 'Vehicles', value: 'VEHICLES' },
                  { label: 'Computers', value: 'COMPUTER' },
                  { label: 'Television', value: 'TELEVISION' },
                  { label: 'Smart Phones', value: 'SMART_PHONES' },
                  { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                ]}
              />
              <InputField
                label="Early Repayment Fee (UGX)"
                error={formState.errors['early_repayment_fee']}
                registration={register('early_repayment_fee')}
              />
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Principal Minimum (UGX)"
                  error={formState.errors['principal_min']}
                  registration={register('principal_min')}
                />
                <InputField
                  label="Principal Maximum (UGX)"
                  error={formState.errors['principal_max']}
                  registration={register('principal_max')}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Interest Rate Minimum (%)"
                  error={formState.errors['interest_rate_min']}
                  registration={register('interest_rate_min')}
                />
                <InputField
                  label="Interest Rate Maximum (%)"
                  error={formState.errors['interest_rate_max']}
                  registration={register('interest_rate_max')}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Period Minimum (Months)"
                  error={formState.errors['period_min']}
                  registration={register('period_min')}
                />
                <InputField
                  label="Period Maximum (Months)"
                  error={formState.errors['period_max']}
                  registration={register('period_max')}
                />
              </div>
              <InputField
                label="Average Lending Rate (%)"
                error={formState.errors['average_lending_rate']}
                registration={register('average_lending_rate')}
              />
              <SelectField
                label="Product Status"
                registration={register('status')}
                options={[
                  {
                    label: 'Active',
                    value: 'ACTIVE',
                  },
                  { label: 'Inactive', value: 'INACTIVE' },
                ]}
              />
            </div>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
