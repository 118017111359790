import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const backtrackLoan = ({ data, id }) => {
  return axios.put(`/tests/loans/${id}/backtrack`, data);
};

export const useBacktrackLoan = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Backtrack successful.',
      });
    },
    ...config,
    mutationFn: backtrackLoan,
  });
};
