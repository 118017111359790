import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import CampaignCreatorsBg from 'assets/campaign-creators.jpg';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col sm:flex-row">
        <div
          className="sm:w-1/2 bg-cover bg-center h-screen hidden sm:block"
          style={{ backgroundImage: `url('${CampaignCreatorsBg}')` }}
        ></div>

        <div className="sm:w-1/2 flex flex-col justify-center py-4 sm:px-6 lg:px-8">
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-orange-100 border border-orange-500 rounded-xl p-4 shadow mb-4">
              <div className="text-xs text-orange-500">
                Running test demo sever @ {process.env.REACT_APP_API_URL}
              </div>
              <div className="font-bold text-orange-500">Testing Credentials</div>
              <div className="text-xs">Phone Number: +256703040046</div>
              <div className="text-xs">Password: finablr123</div>
            </div>
            <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-start">
                  <Link className="flex items-start text-white" to="/">
                    <img className="h-20 w-auto" src={logo} alt="finablr" />
                  </Link>
                </div>

                <h2 className="mt-3 text-start text-xl font-extrabold text-gray-900 dark:text-white">
                  {title}
                </h2>
              </div>
              {children}
              <div className="mt-4 text-sm">
                © 2024, Enabling Finance Limited or its affiliates. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
