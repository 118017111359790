import { Button, Dialog, DialogTitle } from 'components/Elements';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, InputField } from 'components/Form';
import { useBacktrackLoan } from '../api/backtrackLoan';

export function BackTrackLoan({ id }) {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const backtrackMutation = useBacktrackLoan();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Manual Start Date</Button>

      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Manual Start Date
          </DialogTitle>
          <div className="mb-4">
            <Form
              id="backtrack-loan"
              onSubmit={async (values) => {
                await backtrackMutation.mutateAsync({
                  data: {
                    startDate: values.startDate,
                  },
                  id,
                });
                handleClose();
              }}
            >
              {({ formState, register }) => (
                <>
                  <div className="">
                    <InputField
                      label="Date"
                      type="date"
                      error={formState.errors['startDate']}
                      registration={register('startDate')}
                    />
                  </div>
                  <div className="flex flex-row items-center space-x-2 justify-center">
                    <Button size="sm" variant="inverse" type="button" onClick={handleClose}>
                      Close
                    </Button>
                    <Button size="sm" type="submit" isLoading={backtrackMutation.isLoading}>
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
