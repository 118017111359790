import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { LoansList } from '../components/LoansList';
import { useAuth } from 'lib/auth';

export const Loans = () => {
  const { user } = useAuth();
  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
  ];
  const navigate = useNavigate();
  return (
    <ContentLayout title="Loans" end={<Button onClick={() => navigate('/app')}>Back Home</Button>}>
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <Link
            to="/app/loans/check-status"
            className="bg-white border border-primary-100 rounded-xl shadow p-6"
          >
            <div className="text-primary-100 font-semibold text-lg mb-2">Loan Status</div>
            <div className="text-gray-700 text-md">
              Provide loan tracking ID and check status of loan
            </div>
          </Link>

          {user.role === 'ADMIN' ||
            (user.role === 'SUPER_ADMIN' && (
              <Link
                to="/app/contracts"
                className="bg-white border border-primary-100 rounded-xl shadow p-6"
              >
                <div className="text-primary-100 font-semibold text-lg mb-2">Contracts</div>
                <div className="text-gray-700 text-md">
                  Check out ongoing contracts between lenders and borrowers
                </div>
              </Link>
            ))}
          {user.role === 'ADMIN' ||
            user.role === 'LOAN_OFFICER' ||
            (user.role === 'SUPER_ADMIN' && (
              <Link
                to="/app/guarantors"
                className="bg-white border border-primary-100 rounded-xl shadow p-6"
              >
                <div className="text-primary-100 font-semibold text-lg mb-2">Guarantors</div>
                <div className="text-gray-700 text-md">
                  Manage guarantors for loan applications.
                </div>
              </Link>
            ))}
          {user.role === 'ADMIN' ||
            user.role === 'LOAN_OFFICER' ||
            (user.role === 'SUPER_ADMIN' && (
              <Link
                to="/app/delinquencies"
                className="bg-white border border-primary-100 rounded-xl shadow p-6"
              >
                <div className="text-primary-100 font-semibold text-lg mb-2">Delinquencies</div>
                <div className="text-gray-700 text-md">
                  Manage delinquencies for loan applications.
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="mt-8">
        <LoansList />
      </div>
    </ContentLayout>
  );
};
