import PropTypes from 'prop-types';
import * as React from 'react';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';
import { Bell } from 'react-feather';
import { Logo } from './components/Logo';
import { Link } from 'react-router-dom';
import { useNotifications } from 'features/notifications';
import { AdminMode } from './components/AdminMode';
import { Spinner } from 'components/Elements';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();

  const notificationsQuery = useNotifications();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (notificationsQuery.isLoading) {
    return <Spinner />;
  }

  console.log(notificationsQuery.data);

  if (!notificationsQuery.data) return null;

  // compute no of unread notifications
  const unreadNotifications = notificationsQuery.data?.filter(
    (notification) => notification.read === false
  ).length;

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="bg-[#FAFAFA] flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative bg-gray-800 px-4 md:px-24 z-10 items-center flex-shrink-0 flex py-4 shadow">
          <Logo />
          <div className="px-10 hidden md:block text-lg text-gray-100 font-bold truncate overflow-hidden">
            Finablr Test Demo
          </div>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex space-x-6 items-center md:ml-6">
              <div
                className={`hidden text-white md:flex flex-row font-semibold items-center shadow rounded-full px-3 text-sm py-2 space-x-2 bg-${
                  (user.role === 'LENDER' && 'secondary') || (user.role === 'BORROWER' && 'primary')
                }-900`}
              >
                <div
                  className={`rounded-full h-4 w-4 bg-${
                    user.role !== ('BORROWER' || 'LENDER') && 'secondary'
                  }-100`}
                ></div>
                {[
                  'SUPER_ADMIN',
                  'LOAN_OFFICER',
                  'ADMIN',
                  'COLLECTIONS_OFFICER',
                  'RECOVERY_OFFICER',
                  'MARKETING_OFFICER',
                ].includes(user.role) && <AdminMode />}
              </div>
              <Link to="/app/notifications">
                <div className="relative">
                  <Bell className="text-white" />
                  {unreadNotifications > 0 && (
                    <div className="absolute bg-secondary-100 h-6 w-6 -mt-9 ml-2 rounded-full text-center text-white flex items-center justify-center text-xs">
                      {unreadNotifications}
                    </div>
                  )}
                </div>
              </Link>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {children}
          <div className="flex flex-col items-center justify-center space-y-2 my-8">
            <div className="px-10 text-xs text-gray-800 truncate overflow-hidden">
              © {currentYear}, Enabling Finance Limited. or its affiliates. All rights reserved.
            </div>
            <div className="px-10 text-xs text-gray-800 truncate overflow-hidden">
              <Link to="/terms-and-conditions">Terms and Conditions</Link> .{' '}
              <Link to="/privacy-policy">Privacy Policy</Link> .{' '}
              <Link to="/cookie-policy">Cookie Policy</Link> . <Link to="/legal">Legal</Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
