import logo from 'assets/logo.png';
import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { useAuth } from 'lib/auth';
import { Lock } from 'react-feather';
import { useNavigate } from 'react-router';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      <Head title="Finablr" description="Enabling Finance Limited" />
      <div className="bg-white h-[90vh] flex items-center">
        <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="flex flex-row justify-center my-4">
            <img src={logo} alt="Finablr" className="h-32 w-auto" />
          </div>
          <p className="">Official use for Finablr Administrators.</p>
          <div className="bg-orange-100 rounded-full p-2 px-4 mx-auto w-fit text-orange-500 font-bold mt-3 text-sm">
            Test Demo
          </div>
          <div className="mt-8 flex justify-center">
            <Button
              onClick={handleStart}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              }
            >
              {user ? `Welcome Back ${user.basicInformation?.firstName}` : 'Go to Login'}
            </Button>
          </div>
        </div>
      </div>

      <div className="h-12 flex items-center justify-center">
        <p className="text-center text-xs flex flex-row items-center space-x-1">
          <span>
            <span>Maintained by </span>{' '}
          </span>
          <a
            className="flex flex-row items-center bg-gray-700 p-1 px-2 rounded-full"
            href="https://enablingfinancelimited.com"
            target="_blank"
            rel="noreferrer"
          >
            <Lock className="h-3 text-gray-100" />
            <span className="text-gray-100">Enabling Finance Limited</span>
          </a>
        </p>
      </div>
    </>
  );
};
